body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

button:hover {
	cursor: pointer;
}

/* ********************************************************************** */
/* ********************************************************************** */
/* *********************      Social Header        ********************** */
/* ********************************************************************** */
/* ********************************************************************** */

.socialHeaderContainer {
	display: flex;
	align-items: center;
	background-color: rgba(22, 70, 135, 1);
	width: 100%;

	color: white;
	width: 100%;
	height: 48px;
}

.socialEmailIcon {
	margin-left: 1rem;
	margin-top: 3px;
}

.socialPhoneIcon svg {
	margin-left: 1rem;
	margin-right: 0.5rem;
	margin-top: 5px;
	height: 17px;
}

.socialEmail {
	color: white;
	margin-left: 0.5rem;
	margin-right: 1rem;
}

.socialPhone {
	color: white;
	margin-right: 1rem;
	width: 100%;
}

.socialIcons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	margin-right: 1rem;
}

.socialIcon {
	margin-left: 0.5rem;
	margin-right: 1rem;
}

@media screen and (max-width: 600px) {

	/* .socialIcons{
    display:none;
  } */
	.socialEmail,
	.socialEmailIcon {
		display: none;
	}
}

a {
	text-decoration: none;
	color: white;
	transition: all 0.3s ease-in-out;
}

/* ********************************************************************** */
/* ********************************************************************** */
/* ******************(*****       Header        ************************* */
/* ********************************************************************** */
/* ********************************************************************** */

.headerContainer {
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	height: 80px;
	/* border-bottom:rgba(22, 70, 135, 1) 1px solid; */
}

.logo img {
	width: 100px;
	height: 50px;
	margin-left: 1rem;
}

.navMenu {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-left: -140px;
}

.navMenu ul li {
	display: inline;
	margin-right: 1.2rem;
}

.menuItem a {
	color: black !important;
	font-size: 1.2rem;
	font-weight: semi-bold;
	text-decoration: none;
	cursor: pointer;
}
.getQuote {
	display: flex;
	align-items: center;
	color: black;
	transition: all 0.3s ease-in-out;
}

.getQuote:Hover {
	transform: scale(1.1);
}

.getQuote a {
	padding: 0.8em;
	background-color: rgb(209, 29, 35);
	color: white;
	font-weight: bold;
	margin-left: -130px;
	border-radius: 7px;
}

.active {
	border-bottom: rgb(217, 25, 25) solid 2.5px;
	color: rgb(190, 30, 30);
	padding-bottom: 28px;
}

li.active a {
	color: red !important;
}

.mobileMenu {
	display: none;
}

@media screen and (min-width: 800px) {
	.mobileMenuIcon {
		display: none;
	}
}

@media screen and (max-width: 800px) {
	.navMenu {
		display: none;
	}

	.logo img {
		margin-left: -60px;
	}

	.mobileMenuIcon {
		text-align: center;
		align-items: center;
		position: absolute;
		right: 0;
		width: 70px;
		height: 70px;
		margin-top: 40px;
	}

	.mobileMenu {
		display: flex;
		align-items: center;
		height: 100%;
	}

	.mobileMenuContent {
		display: none;
		width: 100%;
		height: 100%;
		background-color: rgba(22, 70, 135, 1);
		position: fixed;
		top: 138px;
		left: 0;
		z-index: 1;
		opacity: 0;
		transition: all 0.3s ease-in-out;
	}

	.mobileMenuContent ul {
		display: flex;
		flex-flow: column;
		align-items: center;
		width: 100%;
		height: 100%;
		margin-left: -40px;
		transition: all 0.3s ease-in-out;
	}

	.mobileMenuContent ul li {
		display: flex;
		width: 100%;
		border-bottom: white solid 2px;
		align-items: center;
		justify-content: center;
	}

	.mobileMenuContent .menuItem a {
		padding-top: 1rem;
		padding-bottom: 1rem;
		color: white !important;
		font-size: 1.2rem;
		font-weight: semi-bold;
		text-decoration: none;
		cursor: pointer;
	}

	.mobileMenuContent li a {
		display: flex;
		align-items: center;
		width: 100%;
		text-align: center;
		justify-content: center;
	}

	.getQuote {
		display: none;
		align-items: center;
		color: white;
	}

	.mobileMenuContent .getQuote a {
		border-radius: 0px;
		padding-top: 1rem;
		padding-bottom: 1rem;
		margin-left: 0px;
	}
}

/* ********************************************************************** */
/* ********************************************************************** */
/* *********************       Hero Section       *********************** */
/* ********************************************************************** */
/* ********************************************************************** */

.heroContainer {
	height: calc(65vh - 138px);
	/* background-image: url(./assets/images/videoforKK.MOV); */
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	animation-name: fade-in;
	animation-duration: 2s;
	justify-content: flex-end;
	position: relative;
}

.heroContent {
	position: absolute;
	display: flex;
	justify-content: flex-end;
	background: rgb(255, 255, 255);
	background: linear-gradient(-90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%);
	width: 100%;
	z-index: 100;
	height: calc(65vh - 138px);
}

.heroButtons {
	display: flex;
	flex-flow: column;
	justify-content: center;
	gap: 1.6rem;

}

.heroButton:first-child {
	background-color: rgb(22, 70, 135);
	color: white;
}

.heroButton {
	padding: 0.8em;
	background-color: rgb(209, 29, 35);
	color: white;
	font-weight: bold;
	border: none;
	font-size: 1.6em;
	width: 500px;
}

@media screen and (max-width: 550px) {
	.heroButton {
		width: auto;
		font-size: 1.3em;
	}
}

.heroButton:hover {
	transition: 0.8ms;
	background-color: gold;
	color: black;

}

.heroText {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-top: 8rem;
	width: 35em;
	margin-left: 8rem;
}

.heroText h1 {
	font-size: 4rem;
	font-weight: bold;
	line-height: 80px;
	color: rgb(22, 70, 135);
	text-shadow: rgba(8, 7, 7, 0.198) 0.1em 0.05em 0.1em;
	margin-bottom: 1rem;
}

.heroText .getQuote a {
	border-radius: 0px;
	width: 200px;
	text-align: center;
}

.heroText .getQuote {
	display: flex;
	margin-top: 1rem;
	margin-left: -150px;
}

.getQuote:hover {
	background-color: white;
	color: black;
}

@media screen and (max-width: 650px) {
	.heroContainer {
		/* background-image: url(./assets/images/KK\ Website\ video.gif); */
		background-size: cover;
		background-position: top;
	}

	.heroContent {
		width: 100%;
		background: rgb(0, 0, 0);
		background: linear-gradient(90deg,
				rgba(0, 0, 0, 0.393) 0%,
				rgba(255, 255, 255, 0) 100%);
	}

	.heroText {
		text-align: center;
		width: 100%;
		margin: 0;
	}

	.heroText .getQuote {
		margin-left: 8rem;
	}

	.heroText h1 {
		font-size: 3.5rem;
		color: white;
		text-shadow: rgba(0, 0, 0, 0.254) 0.1em 0.1em 0.2em;
	}

	#getQuote a:hover {
		color: black;
	}
}

/* ********************************************************************** */
/* ********************************************************************** */
/* *********************       Our Services       *********************** */
/* ********************************************************************** */
/* ********************************************************************** */

.servicesContainer {
	padding: 2em;
	background-color: rgb(255, 255, 255);
	background-color: linear-gradient(90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%);

	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
}

.services {
	display: grid;
	grid-template-columns: repeat(3, minmax(250px, 1fr));
	grid-gap: 4rem;
}

@media screen and (max-width: 1100px) {
	.services {
		grid-template-columns: repeat(3, minmax(250px, 1fr));
	}
}

@media screen and (max-width: 900px) {
	.services {
		grid-template-columns: repeat(2, minmax(250px, 1fr));
	}
}

@media screen and (max-width: 600px) {
	.services {
		grid-template-columns: repeat(1, minmax(250px, 1fr));
	}
}

.service {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: 250px;
	width: 250px;
	margin-top: 0;
	border-radius: 1rem;
}

.service p {
	text-align: center;
}

.service img {
	width: 240px;
	height: auto;
	object-fit: cover;
}

.service svg {
	height: 50px;
	position: relative;
	margin-top: -27px;
}

.service:hover {
	cursor: pointer;
	background-color: rgba(253, 254, 255, 0);
	box-shadow: 0px 50px 50px -38px rgba(0, 11, 158, 0.252);
	transform: scale(1.1);
	transition: all 0.3s ease-in-out;
}

.service:hover svg circle {
	fill: gold !important;
	transition: all 0.3s ease-in-out;
}

.servicesContainer h4 {
	font-weight: 700;
	color: rgb(209, 29, 35);
	font-size: 1.5em;
	text-align: center;
	margin-bottom: 0;
}

.service h3 {
	font-weight: bold;
	color: black;
	font-size: 1.4em;
	text-align: center;
	margin-bottom: 0;
	margin-top: 10px;
	padding-bottom: 1em;
}

.service p {
	color: rgb(22, 70, 135);
	font-size: 1em;
	text-align: center;
	margin-bottom: 0;
	padding: 1em;
	margin-top: 0;
}

/* ********************************************************************** */
/* ********************************************************************** */
/* *********************      About Section        ********************** */
/* ********************************************************************** */
/* ********************************************************************** */

.aboutContainer {
	padding: 2em;
	background-color: rgb(255, 255, 255);
	display: flex;
	flex-flow: row;
	width: 90%;
	padding: 1em;
}

.aboutImage {
	/* background-image: url("./assets/images/truckwithbox.webp"); */
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	min-height: 500px;
	animation-name: fade-in;
	animation-duration: 3s;
}

.about {
	width: 70%;
	padding: 3em;
}

.about h4 {
	font-weight: 500;
	color: red;
}

.about h2 {
	font-size: 2em;
	font-weight: 900;
	color: rgb(194, 15, 15);
}

.about p {
	line-height: 26px;
	font-weight: 300;
	letter-spacing: 3px;
	color: rgba(22, 70, 135, 1);
}

@media screen and (max-width: 1100px) {
	.aboutContainer {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

/* ********************************************************************** */
/* ********************************************************************** */
/* *******************      Portfolio Section        ******************** */
/* ********************************************************************** */
/* ********************************************************************** */

.workContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	animation-name: fade-in;
	animation-duration: 3s;
}

.workContainer h2 {
	font-size: 2em;
	font-weight: 900;
	color: rgb(194, 15, 15);
}

.image-gallery {
	width: 650px;
}

@media screen and (max-width: 800px) {
	.image-gallery {
		width: 100%;
	}
}

/* ********************************************************************** */
/* ********************************************************************** */
/* *******************      ChooseUs Section        ********************* */
/* ********************************************************************** */
/* ********************************************************************** */

.chooseUs {
	margin-top: 6em;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: rgba(255, 247, 247, 1);

	min-height: 80vh;
}

.chooseUs .content {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	min-width: 100%;
	padding: 0;
}

.left {
	padding: 0;
	display: flex;
	justify-content: center;
	width: 50%;
	min-width: 450px;
	max-width: 100vh;
}

.left img {
	width: 70%;
}

.right {
	padding: 0;
	display: flex;
	justify-content: center;
	width: 50%;
	min-width: 400px;
}

.right img {
	width: 90%;
}

.chooseUs h2 {
	padding: 1em;
	font-size: 2em;
	font-weight: 900;
	color: rgb(194, 15, 15);
	max-width: 500px;
	text-align: center;
}

/* ********************************************************************** */
/* ********************************************************************** */
/* ******************      ContactUs Section        ********************* */
/* ********************************************************************** */
/* ********************************************************************** */

.contact {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}

.contactImage {
	height:650px;
	overflow-x: scroll;
	overflow-y: hidden;
	width: 50%;
	display: flex;
	background-color: white;
}

.formContainer {
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 50%;
	min-width: 325px;
}

.form {
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 1em;
}

.form input,
textarea {
	display: flex;
	justify-content: center;
	padding: 1.2em;
	margin-bottom: 10px;
	margin-top: 3px;
	width: 50%;
	color: rgb(4, 12, 122);
	border: solid 1px rgba(180, 182, 207, 0.777);
	border-radius: 3px;
}

.contactImage img {
	display: flex;
	/* width: 100%; */
	height: auto;
}

.form button {
	position: relative;
	padding: 0.8em;
	background-color: rgb(209, 29, 35);
	color: white;
	font-weight: bold;
	border-radius: 7px;
	border: none;
	font-size: 1em;
	margin-left: -43.5%;
}

.form button:hover {
	background-color: white;
	color: rgb(22, 70, 135);
	border: 2px solid red;
}

.formContainer h2 {
	font-size: 2em;
	font-weight: 900;
	color: rgb(194, 15, 15);
	text-align: center;
}

@media screen and (max-width: 800px) {
	.contact {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.contactImage {
		margin-top: 2em;
		display: flex;
		background-color: bisque;
		width:auto;
	}

	.form input,
	textarea {
		width: 90%;
	}

	.form button {
		margin-left: -75.5%;
	}
}

/* ********************************************************************** */
/* ********************************************************************** */
/* ********************      Estimate Banner        ********************* */
/* ********************************************************************** */
/* ********************************************************************** */

.estimateContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	background-color: rgb(194, 15, 15);
	min-height: 300px;
	color: white;
	padding: 0 6em 0 6em;
}

.estimateContainer h2 {
	padding: 0px;
	font-size: 32px;
	max-width: 500px;
	min-width: 375px;
}

.estimateContainer button {
	padding: 0.8em;
	background-color: white;
	color: rgb(209, 29, 35);
	font-weight: bold;
	border-radius: 3px;
	border: none;
	font-size: 1em;
	min-width: 150px;
}

.estimateContainer a {
	color: red;
}

.estimateContainer button:hover {
	background-color: white;
	color: rgb(22, 70, 135);
	border: 2px solid red;
	transition: ease-in 0.8ms;
}

@media screen and (max-width: 850px) {
	.estimateContainer {
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: space-around;
		flex-flow: column wrap;
		background-color: rgb(194, 15, 15);
		min-height: 300px;
		color: white;
		padding: 20px;
	}

	.estimateContainer button {
		margin-top: -50px;
	}
}

/* ********************************************************************** */
/* ********************************************************************** */
/* ********************      Footer Section        ********************** */
/* ********************************************************************** */
/* ********************************************************************** */

.footerContainer {
	display: flex;
	flex-direction: column;
	/* background-color: #f5f5f5;
	 */
	background-color: white;
}

.footerTopSection {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	padding: 1.6em;
}

.footerLogoArea {
	max-width: 390px;
	/* min-width: 390px; */
	margin-right: 4em;
}

.footerLogo {
	background-image: url("./assets/logo/KleanKingLogo.webp");
	background-size: contain;
	width: 100px;
	height: 50px;
	background-repeat: no-repeat;
	margin-bottom: 1em;
}

.footerLogoText {
	color: rgb(22, 70, 135);
	font-size: 16px;
	line-height: 23px;
	font-weight: 300;
	margin-bottom: 1em;
}

.footerSocialIcons {
	display: flex;
	flex-flow: row wrap;
}

.footerSocialIcons svg {
	margin-right: 1em;
}

.footerLinks {
	display: flex;
}

.quickLinksCol {
	display: flex;
	flex-direction: column;
	min-width: 150px;
}

.contactInfoCol {
	display: flex;
	flex-direction: column;
	min-width: 150px;
}

.contactInfoCol a {
	color: rgb(22, 70, 135);
}

.footerLinks .footerLinksHeader {
	margin-bottom: 1.4em;
	font-size: 24px;
	font-weight: 600;
}

.contactInfoCol .footerLink {
	display: flex;
}

.contactInfoCol .footerLink svg {
	margin-right: 1em;
}

.footerLinks>* {
	margin-right: 4.2em;
	display: flex;
}

.footerLinks .footerLink {
	margin-bottom: 1.2em;
	font-size: 16px;
	color: rgb(22, 70, 135);
	font-weight: 300;
}

.copyright {
	width: 100%;
	text-align: center;
	padding-bottom: 1em;
	padding-top: 1em;
	border-top: 1px rgba(115, 115, 115, 0.5) solid;
}

.copyright a {
	color: rgb(190, 30, 30);
	font-weight: 800;
}

@media screen and (max-width: 900px) {
	.footerTopSection {
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		padding: 1.6em;
	}

	.footerLinks {
		flex-flow: column wrap;
		justify-content: center;
	}
}

/* ********************************************************************** */
/* ********************************************************************** */
/* *********************       Service Page       *********************** */
/* ********************************************************************** */
/* ********************************************************************** */
.carpet {
	background-image: url("./assets/images/titleimages/carpet.webp");
}

.tile {
	background-image: url("./assets/images/titleimages/tile.webp");
}

.air {
	background-image: url("./assets/images/titleimages/air.webp");
}

.dryer {
	background-image: url("./assets/images/titleimages/dryer.webp");
}

.crime {
	background-image: url("./assets/images/titleimages/crime.webp");
}

.mold {
	background-image: url("./assets/images/titleimages/mold.webp");
}

.upholstery {
	background-image: url("./assets/images/titleimages/upholstery.webp");
}

.hardwood {
	background-image: url("./assets/images/titleimages/hardwood.webp");
}

.water {
	background-image: url("./assets/images/titleimages/water.webp");
}

.pageTitle {
	display: flex;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	height: 20vw !important;
	justify-content: center;
	align-items: center;
	width: 100% !important;
	max-height: 275px;
}

.pageTitle h1 {
	color: white;
	font-size: 4.5em;
	text-align: center;
}

.servicePageContent {
	display: flex;
	flex-flow: row wrap;
	width: 100vw;
	justify-content: space-evenly;
	margin-bottom: 4em;
}

.servicePageText {
	width: 55%;
	min-width: 330px;
	margin-top: 1em;
	margin-bottom: 1em;
}

.servicePageText h2 {
	font-size: 38px;
	text-align: Left;
	margin-left: 20px;
	color: rgb(4, 12, 122);
}

.servicePageText p {
	padding: 1em;
	padding-top: 0px;
	line-height: 32px;
	letter-spacing: 1.2px;
	font-size: 18px;
}

.servicePageSidebar {
	display: flex;
	flex-flow: column;
	margin-top: 50px;
}

.servicePageSidebar h3 {
	text-align: center;
	color: rgb(217, 25, 25);
	font-size: 24px;
	margin-bottom: 0px;
}

.servicePageSidebar ul {
	display: flex;
	flex-direction: column;
	padding: 0;
	text-align: center;
}

.servicePageSidebar ul li {
	list-style-type: none;
	text-decoration: none;
	color: rgb(4, 12, 122);
	border-bottom: 1px red solid;
	padding-bottom: 10px;
	padding-top: 10px;
	font-weight: 600;
}

.servicePageSidebar li:hover {
	transform: scale(1.2) !important;
	cursor: pointer;
}

/* ********************************************************************** */
/* ********************************************************************** */
/* *********************       Landing Page       *********************** */
/* ********************************************************************** */
/* ********************************************************************** */

.landingPage {
	display: flex;
	flex-flow: column;
	width: 100vw;
	overflow-x: hidden;
	justify-content: center;
	align-items: center;
	margin-bottom: 4em;
}

.landingPage h2 {
	font-size: 2em;
	font-weight: 900;
	color: rgb(194, 15, 15);
}

.landingPage .hero-section {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-color: red;
	/* min-height: 450px; */
}

.landingPage .footer-section {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	background-color: red;
	min-width: 100vw;
	position: relative;
	bottom: 0;
}


.empContainer {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	gap: 20px;
	padding: 20px;
}

@media screen and (max-width: 520px) {
	.empContainer {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		justify-items: center;
		/* Center align items */
	}

	/* Remove specific column assignments for the 4th and 5th items */
	.empContainer> :nth-child(4),
	.empContainer> :nth-child(5) {
		margin: 0 -115% 0 0 !important;
	}

}

.serviceButton {
	padding: 0.8em;
	background-color: rgb(209, 29, 35);
	color: white;
	font-weight: bold;
	border-radius: 7px;
	border: none;
	font-size: 1.2em;
	width: 250px;
	box-shadow: 0px 50px 50px -38px rgba(0, 11, 158, 0.494);
}

.serviceButton:hover {
	transition: 0.8s;
	background-color: gold;
	color: black;

}

.servicesButtons {
	padding: 2em;
	margin: 0 auto 0 auto;
	display: grid;
	gap: 1em;
	grid-template-columns: repeat(3, 1fr);
	justify-items: center;
}

@media screen and (max-width: 800px) {
	.servicesButtons {
		grid-template-columns: repeat(2, 1fr);
		padding: 0;
	}

	.serviceButton {
		font-size: 1em;
		width: 200px;
	}
}


.aboutVideoSection {
	min-height: 600px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1rem;
}

.aboutVideo {
	object-fit: cover;
	border-radius: 10px;
	border: 1rem solid red;
	min-height: 300px;
	width:700px;
}

@media screen and (max-width: 800px) {
	.aboutVideoSection{
		margin:0;
		height:300px;
		min-height: 450px;
	}
	.aboutVideo {
		width: 80%;
	}
}